import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import _ from "lodash";
const DetailsRow = ({ heading, data, url, fullUrl, numeric }) => {
  return (
    <tr>
      <th>{heading}</th>
      <td className={numeric ? "num-value" : null}>
        {url ? (
          <Row className="align-items-center">
            <Col>
              <Link to={url}>{data}</Link>
            </Col>
          </Row>
        ) : fullUrl ? (
          <Row className="align-items-center">
            <Col>
              <a href={fullUrl} target="_blank">{data}</a>
            </Col>
          </Row>
        ) : (
          data
        )}
      </td>
    </tr>
  );
};

export default DetailsRow;
